<template>
  <div class='CourseDiscuss'>
    <StudioAdminTabs :list="tabs"></StudioAdminTabs>
    <!-- 文章评论管理 -->
    <div class="navC">
      <span class="fz16" style="color: #FF8201;">注：可绑定本工作室自运营的微信公众号，实现工作室优质原创推送至公众号。</span>
    </div>
    <div class="contentBox">
      <div class="tableList">
        <div class="ulBox df">
          <div class="liBox" v-for="(item, index) in tableData" :key="index">
            <div class="imgBox">
              <img class="imgbig" src="@/assets/images/paizhao.png" alt="">
              <div class="aboutBox cp" @click="toDelete(item)">
                <i class="el-icon-close fz18"></i>
              </div>
            </div>
            <div class="txtBox">
              <p class="ellipsis-1 tc">{{ item.title }}</p>
            </div>
          </div>
          <div class="lastBox" @click="goaddmsBangDing">
            <img class="imgbig" src="@/assets/images/paizhao.png" alt="">
          </div>
        </div>
      </div>
      <div class="block" v-show="total">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as accountApi from "@/api/teacherStudio/accountApi"
export default {
  data() {
    return {
      ids: [],
      input: '',
      tableData: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_content: '',
        search_name: '',
        studio_id: localStorage.getItem('studioId'),
      },
      tabs: [
        {
          label: "公众号绑定管理",
          count: 0
        },
      ],
    }
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //初始化
    this._accountlists();
  },
  computed: {
  },
  methods: {
    goaddmsBangDing() {
      this.$router.push({
        name: "addmsBangDing"
      })
    },
    _accountlists() {
      accountApi.accountlists(this.form).then(res => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      })
    },
    //删除
    toDelete(row) {
      this.$confirm(`移除后不再支持向该公众号推送内容。`, '是否确认移除该公众号？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        accountApi.delaccount({ studio_id: localStorage.getItem('studioId'), del_id: row.id }).then(res => {
          this.$message.success(`解绑成功`)
          this.form.page = 1
          this._accountlists()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消解绑'
        });
      });
    },
    handleCurrentChange(val) {
      this.form.page = val
      this._accountlists();
    }
  },
}
</script>
<style lang="less" scoped>
.CourseDiscuss {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
    font-weight: bold;
  }

  //内容盒子
  .contentBox {

    //表格
    .tableList {
      padding: 0 24px 24px 24px;

      .ulBox {
        padding-top: 20px;
        flex-wrap: wrap;

        .lastBox {
          cursor: pointer;
          width: 156px;
          height: 156px;
          background: rgba(238, 246, 255, 1);
          border: 1px solid rgba(220, 231, 243, 1);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 56px;
            height: 56px;
          }
        }

        .liBox {
          margin-right: 22px;
          margin-bottom: 20px;

          &:hover {
            .imgBox {
              .aboutBox {
                display: block;
              }
            }
          }

          &:nth-child(6n) {
            margin-right: 0;
          }

          .imgBox {
            width: 156px;
            height: 156px;
            border: 1px solid rgba(220, 231, 243, 1);
            border-radius: 8px;
            position: relative;

            .aboutBox {
              display: none;
              position: absolute;
              top: 0;
              right: 0;
              width: 28px;
              height: 24px;
              background: rgba(220, 231, 243, 1);
              border-radius: 0 8px 0 8px;
              text-align: center;
              line-height: 24px;
            }
          }

          .txtBox {
            width: 156px;
            padding: 10px 0;

            p {
              padding: 0 5px;
            }
          }
        }
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
