import Vue from "vue";
let request = Vue.prototype.$axios

// 四名活动列表
export function accountlists(params) {
    return request({
        url: 'index/weChat/account/lists',
        method: 'get',
        params,
    })
};

// 四名活动列表
export function delaccount(params) {
    return request({
        url: 'index/weChat/account/del',
        method: 'delete',
        params,
    })
};

// 四名活动列表
export function addaccount(params) {
    return request({
        url: 'index/weChat/account/add',
        method: 'post',
        data: params,
    })
};